<template>
  <v-container>
    <v-row>
      <v-col
        cols="6"
        class="mt-3 pa-4 text-left"
      >
        <v-icon
          class="mb-3"
          large
        >
          mdi-account-multiple
        </v-icon>
        <span class="text-h2"> Contacts</span>
      </v-col>
      <v-col
        cols="6"
        class="text-right"
      >
        <v-btn
          fab
          large
          color="primary"
          @click="createContactCardDialog = true"
        >
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="searchQueryContactCards"
          outlined
          label="Search Contacts"
          @keyup.enter.native="searchContactCards"
          append-icon="mdi-magnify"
          @keyup.native="searchContactCards"
          @click:append="searchContactCards"
          clearable
          @click:clear="clearSearch"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row class="py-0 my-0">
      <v-col
        v-for="user in users"
        :key="user.contactCard.id"
        cols="12"
        md="3"
        class="py-0 my-0 mt-16"
      >
        <theme-material-card
          style="height: 100%;"
          class="v-card-profile"
          :avatar="
            user.contactCard.profilePicture
              ? user.contactCard.profilePicture.urlThumb
              : 'contact-placeholder.jpg'
          "
        >
          <v-col
            cols="12"
            class="text-right py-0 my-0"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ attrs, on }">
                <v-btn
                  v-bind="attrs"
                  class="mx-1 my-n8"
                  color="primary"
                  icon
                  v-on="on"
                  @click="
                    editContactCardDialog = true;
                    selectedUserForEdit = user;
                  "
                >
                  <v-icon> mdi-cog </v-icon>
                </v-btn>
              </template>

              <span>Edit</span>
            </v-tooltip>
          </v-col>
          <v-card-text class="text-center">
            <h4
              v-if="user.contactCard.firstName"
              class="display-2 font-weight-light mb-3 grey--text"
            />
            <span
              v-if="user.contactCard.firstName"
              class="text-h3"
            >
              {{ user.contactCard.firstName }}
              {{ user.contactCard.surname }}</span><br/>
            <span class="display-1 mb-1 grey--text">{{
              user.contactCard.jobTitle
            }}</span>
          </v-card-text>
          <v-row>
            <div class="mb-4 text-center" style="width: 100%; opacity: 0.4">{{ user.contactCard.stakeholder.name }}</div>
          </v-row>
          <template v-slot:actions>
      

            <v-spacer />
            <div class="display-2 font-weight-light grey--text">
              <v-icon
                small
                color="primary"
              >
                mdi-email
              </v-icon>
              <a
                :href="'mailto:' + user.contactCard.email"
                class="caption primary--text font-weight-light"
              >
                {{ user.contactCard.email }}</a>
            </div>
          </template>
        </theme-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-if="selectedUserForEdit"
      v-model="editContactCardDialog"
      width="600"
    >
      <v-card class="pb-4">
        <v-card-title class="white--text primary">
          Edit Contact
        </v-card-title>
        <v-row class="ma-4">
          <v-col
            cols="12"
            class="text-center"
          >
            <v-avatar size="180">
              <img
                style="cursor:pointer;"
                :src="
                  profilePictureURL === null
                    ? selectedUserForEdit.contactCard.profilePicture === null? 'contact-placeholder.jpg' :selectedUserForEdit.contactCard.profilePicture.urlThumb
                    : profilePictureURL
                "
                @click="$refs.contactPicUpload.click()"
              >
            </v-avatar>
            <input
              ref="contactPicUpload"
              style="display:none;"
              type="file"
              accept="image/*"
              @change="addNewImgToCropperEdit"
            >
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="selectedUserForEdit.contactCard.email"
              outlined
              label="Email"
              type="email"
              :disabled="editContactCard"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="selectedUserForEdit.contactCard.firstName"
              outlined
              label="First Name"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="selectedUserForEdit.contactCard.surname"
              outlined
              label="Surname"
            />
          </v-col>
     <!--     <v-col
            cols="6"
          >
            <v-text-field
              v-model="selectedUserForEdit.contactCard.phoneNumber"
              outlined
              label="Phone Number"
            />
          </v-col> -->
          
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="selectedUserForEdit.contactCard.jobTitle"
              outlined
              label="Job Title"
            />
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="selectedUserForEdit.contactCard.countryCode"
              outlined
              :items="countryCodeOptions"
              item-text="name"
              :item-value="'code'"
              label="Country"
            />
          </v-col>
          <v-col
            cols="6"
          >
            <v-select
              v-model="selectedUserForEdit.contactCard.stakeholder.id"
              outlined
              label="Stakeholder"
              :items="stakeholders"
              item-text="friendlyName"
              :item-value="'id'"
            />
          </v-col>
        </v-row>

        <v-row class="ma-4">
          <v-col
            cols="12"
            class="text-right"
          >
            <v-btn
              color="primary"
              @click="editContactCard"
            >
              SAVE
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-form
      ref="userForm"
      v-model="validUser"
    >
      <v-dialog
        v-if="newContactCard"
        v-model="createContactCardDialog"
        width="620"
      >
        <v-card>
          <v-card-title class="white--text primary">
            Create Contact
          </v-card-title>
          <v-row class="text-center pa-0 ma-0">
            <v-col cols="12">
              <v-avatar size="180">
                <img
                  style="cursor:pointer;"
                  :src="profilePictureURL === null ? 'contact-placeholder.jpg' : profilePictureURL"
                  @click="$refs.contactPicUpload.click()"
                >
              </v-avatar>
              <input
                ref="contactPicUpload"
                style="display:none;"
                type="file"
                accept="image/*"
                @change="addNewImgToCropper"
              >
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="newContactCard.email"
                outlined
                label="Email*"
                type="email"
                :rules="requiredRule"
                
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="newContactCard.firstName"
                outlined
                label="First Name*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-text-field
                v-model="newContactCard.surname"
                outlined
                label="Surname*"
                :rules="requiredRule"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="newContactCard.countryCode"
                outlined
                :items="countryCodeOptions"
                item-text="name"
                :item-value="'code'"
                label="Country"
              />
            </v-col>
      <!--       <v-col
              cols="6"
            >
              <v-text-field
                v-model="newContactCard.phoneNumber"
                outlined
                label="Phone Number"
                type="number"
              />
            </v-col> -->

            <v-col
              cols="6"
            >
              <v-text-field
                v-model="newContactCard.jobTitle"
                outlined
                label="Job Title"
              />
            </v-col>
            <v-col
              cols="6"
            >
              <v-select
                v-model="newContactCard.stakeholderId"
                outlined
                label="Stakeholder*"
                :rules="requiredRule"
                :items="stakeholders"
                item-text="friendlyName"
                :item-value="'id'"
              />
            </v-col>

            <v-col
              cols="12"
              class="text-right"
            >
              <v-btn
                color="primary"
                @click="createContactCard"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-form>

    <v-dialog
      v-model="promptCreateUserDialog"
      width="600"
    >
      <v-card class="pa-4">
        <span>Contact created successfully, would you like to create a user account
          for this person?</span>
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="selectedRole"
              outlined
              label="Select Role For New User*"
              :items="roles"
              item-text="name"
              :item-value="'id'"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="selectedRole === null"
            @click="createUserAccForNewContactCard"
          >
            Confirm
          </v-btn>
          <v-btn
            color="white"
            @click="
              promptCreateUserDialog = false;
              createdContactCardId = null;
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cropperDialog"
      width="600"
    >
      <v-card class="pa-4">
        <v-col cols="12">
          <cropper
            ref="cropper"
            class="cropper"
            :src="imgUrl"
            :stencil-component="$options.components.CircleStencil"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-right"
        >
          <v-btn
            color="primary"
            @click="cropImage"
          >
            crop
          </v-btn>
        </v-col>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import UserController from '@/services/controllers/User'
  import StakeholderController from '@/services/controllers/Stakeholder'
  import CampaignController from '@/services/controllers/Campaign'
  import countryPhonePrefixes from '../../../../public/country-phone-prefix.json'
  import { Cropper, CircleStencil } from 'vue-advanced-cropper'

  export default {
    components: {
      Cropper,
      CircleStencil,
      countryPhonePrefixes,
    },
    data: () => ({
      contactCardStakeholderId: null,
      uploadedContactCardLogo: null,
      uploadedContactCardLogoEdit: null,
      createdContactCardId: null,
      logoMediaId: null,
      searchQueryContactCards: null,
      selectedRole: null,
      croppedImg: null,
      promptCreateUserDialog: false,
      imgUrl: null,
      requiredRule: [(v) => !!v || 'Field is required'],
      stakeholders: [],
      mediaUploadId: null,
      profilePictureURL: null,
      roles: [],
      cropperDialog: false,
      newContactCard: {
        firstName: null,
        surname: null,
        countryCode: null,
        //phoneNumber: null,
        email: null,
        jobTitle: null,
        stakeholderId: 0,
      },
      createUserDialog: false,
      createContactCardDialog: false,
      role: null,
      email: null,
      editContactCardDialog: false,
      validUser: false,
      countryCodeOptions: countryPhonePrefixes,
      stakeholder: null,
      createUserValid: false,
      requiredRule: [(v) => !!v || 'Field is required'],
      snackbarSuccessMsg: null,
      snackbarSuccess: false,
      snackbarError: false,
      snackbarErrorMsg: null,
      selectedUserForEdit: null,
      canDeleteUser: false,
      canEditUser: false,
      searchUsers: '',
      headers: [
        {
          text: 'Forename',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Media Owner',
          align: 'start',
          sortable: true,
          value: 'mediaOwner.name',
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'category',
        },
        {
          text: 'Height',
          align: 'start',
          sortable: true,
          value: 'height',
        },
        {
          text: 'Width',
          align: 'start',
          sortable: true,
          value: 'width',
        },
        {
          text: 'Orientation',
          align: 'start',
          sortable: true,
          value: 'orientation',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
        },
      ],
      users: [],
    }),
    computed: {
      stakeholderLogo: (app) => (id) => {
        const search = (what) => app.stakeholders.find((element) => element.id === what)
        
        if (search(parseInt(id))) {
          const res = search(parseInt(id))
          return (res.logo) ? res.logo : 'contact-placeholder.jpg'
        } 
        else {
          return 'contact-placeholder.jpg'
        }
      },
    },
    created () {
      this.getRoles()
      this.getAllUsers()
      StakeholderController.allStakeholders().then((res) => {
        this.stakeholders = res.data
      })
    },
    methods: {
      cropImage () {
        const { canvas } = this.$refs.cropper.getResult()
        let resultFromApi
        var checkForResult = setInterval(() => {
          if (resultFromApi !== undefined) {
            clearInterval(checkForResult)
            this.mediaUploadId = resultFromApi.id
            this.profilePictureURL = resultFromApi.url
            this.imgUrl = null
            this.cropperDialog = false
          }
        }, 100)
        if (canvas) {
          const form = new FormData()
          canvas.toBlob((blob) => {
            var reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = function () {
              var base64data = reader.result

              form.append('fileBase64', base64data)
              form.append('campaignId', 0)

              CampaignController.uploadB64(form)
                .then((b64res) => {
                  resultFromApi = b64res.data
                })
                .catch((err) => {
                  clearInterval(checkForResult)
                })
            }
          }, 'image/png')
        }
      },
      // if user wants to create user account for new contact card
      createUserAccForNewContactCard () {
        UserController.createUserFromContactCard({
          contactCardId: this.createdContactCardId,
          roleId: this.selectedRole,
        })
          .then((res) => {
            this.createdContactCardId = null
            this.selectedRole = null
            this.$root.$emit(
              'snackbarSuccess',
              'User account created successfully.',
            )
            this.promptCreateUserDialog = false
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },
      clearSearch () {
        this.searchQueryContactCards = null
        this.getAllUsers()
      },
      searchContactCards () {
        UserController.searchContactCards(this.searchQueryContactCards).then(
          (res) => {
            this.users = res.data
          },
        )
      },
      addNewImgToCropper (e) {
        this.uploadedContactCardLogo = Object.values(e.target.files)[0]
        this.imgUrl = URL.createObjectURL(this.uploadedContactCardLogo)
        this.cropperDialog = true
      },
      addNewImgToCropperEdit (e) {
        this.uploadedContactCardLogoEdit = Object.values(e.target.files)[0]
        this.imgUrl = URL.createObjectURL(this.uploadedContactCardLogoEdit)
        this.cropperDialog = true
      },
      imageCroppedSuccess (ev) {
        this.cropperDialog = false
        this.croppedImgB64 = ev
        const formData = new FormData()
        formData.append('fileBase64', this.croppedImgB64)
        formData.append('campaignId', 0)

        CampaignController.uploadB64(formData).then((b64res) => {
          this.mediaUploadId = b64res.data.id
        })
      },
      createContactCard () {
        this.$refs.userForm.validate()
        if (this.validUser === true) {
          UserController.createContactCard({
            firstName: this.newContactCard.firstName,
            surname: this.newContactCard.surname,
            countryCode: this.newContactCard.countryCode,
            phoneNumber: this.newContactCard.phoneNumber,
            jobTitle: this.newContactCard.jobTitle,
            email: this.newContactCard.email,
            stakeholderId: this.newContactCard.stakeholderId,
            profilePictureId:
              this.mediaUploadId === null ? 0 : this.mediaUploadId,
          })
            .then((res) => {
              this.mediaUploadId = null
              this.uploadedContactCardLogo = null
              this.profilePictureURL = null
              this.imgUrl = null
              this.newContactCard = {
                firstName: null,
                surname: null,
                countryCode: null,
                phoneNumber: null,
                email: null,
                jobTitle: null,
                stakeholderId: 0,
              }
              this.createContactCardDialog = false
              this.getAllUsers()
              this.createdContactCardId = res.data.id
              this.promptCreateUserDialog = true
              this.$root.$emit(
                'snackbarSuccess',
                'Contact created successfully.',
              )
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+err.response.data.message)
            })
        }
      },
      editContactCard () {
        UserController.editContactCard({
          id: this.selectedUserForEdit.contactCard.id,
          firstName: this.selectedUserForEdit.contactCard.firstName,
          surname: this.selectedUserForEdit.contactCard.surname,
          countryCode: this.selectedUserForEdit.contactCard.countryCode,
          phoneNumber: this.selectedUserForEdit.contactCard.phoneNumber,
          jobTitle: this.selectedUserForEdit.contactCard.jobTitle,
          email: this.selectedUserForEdit.contactCard.email,
          stakeholderId: this.selectedUserForEdit.contactCard.stakeholder.id,
          profilePictureId: this.mediaUploadId === null ? 0 : this.mediaUploadId,
        })
          .then((res) => {
            this.getAllUsers()
            this.imgUrl = null
            this.mediaUploadId = null
            this.selectedUserForEdit = null
            this.editContactCardDialog = false
            this.profilePictureURL = null
            this.$root.$emit('snackbarSuccess', 'Contact edited successfully.')
          })
          .catch((err) => {
            this.$root.$emit('snackbarError', ''+err.response.data.message)
          })
      },
      createUser () {
        this.$refs.createUserForm.validate()
        if (this.createUserValid) {
          // Passed validation
          UserController.createUser({
            stakeholderId: this.stakeholder,
            email: this.email,
            roleId: this.role,
          })
            .then((res) => {
              this.stakeholder = null
              this.email = null
              this.role = null;
              (this.createUserDialog = false),
              this.$root.$emit('snackbarSuccess', 'User added successfully.')
            })
            .catch((err) => {
              this.$root.$emit('snackbarError', ''+err.response.data.message)
            })
        }
      },
      getAllUsers () {
        UserController.getUsers().then((res) => {
          this.users = res.data
        })
      },
      getAllStakeholders () {
        StakeholderController.allStakeholders().then((res) => {
          this.stakeholders = res.data
        })
      },
      getRoles () {
        StakeholderController.getPrimaryRoles().then((res) => {
          this.roles = res.data
        })
      },
    },
  }
</script>
<style>
a {
  text-decoration: none !important;
}
.cropper {
  height: 600px;
}
/* */
</style>
